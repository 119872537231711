import 'react-responsive-carousel/lib/styles/carousel.min.css';

import React from 'react';
import { createUseStyles } from 'react-jss';
import { Carousel as ReactCarousel } from 'react-responsive-carousel';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/outline';
import { pxToRem, Theme } from 'src/theme';
import IconButton from 'src/components/core/IconButton';
import { SetState } from 'src/types';

interface Props {
  activeIdx: number,
  setActiveIdx: SetState<number>,
  isLight: boolean,
  children: React.ReactChild[],
}

interface StyleProps {
  isLight?: boolean,
}

const useStyles = createUseStyles<string, StyleProps, Theme>((theme) => ({
  carousel: {
    maxWidth: 1000,
    '& > .carousel': {
      display: 'flex',
      '& .slide': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  },
  arrowIcon: {
    width: pxToRem(30),
    height: pxToRem(30),
    minWidth: pxToRem(30),
    minHeight: pxToRem(30),
    marginTop: 'auto',
    marginBottom: 'auto',
    color: theme.palette.primary.TEAL,
    '&:hover': {
      color: '#78E2E5',
    },
  },
}));

const ScreenshotCarousel: React.FC<Props> = ({
  activeIdx, setActiveIdx, isLight, children,
}) => {
  const classes = useStyles({ isLight });
  return (
    <ReactCarousel
      swipeable={false}
      className={classes.carousel}
      selectedItem={activeIdx}
      onChange={(i) => setActiveIdx(i)}
      infiniteLoop
      renderArrowPrev={((onClick) => (
        <IconButton onClick={onClick} style={{ paddingLeft: 0 }}>
          <ChevronLeftIcon className={classes.arrowIcon} />
        </IconButton>
      ))}
      renderArrowNext={((onClick) => (
        <IconButton onClick={onClick} style={{ paddingRight: 0 }}>
          <ChevronRightIcon className={classes.arrowIcon} />
        </IconButton>
      ))}
      showThumbs={false}
      showStatus={false}
      showIndicators={false}
    >
      {children}
    </ReactCarousel>
  );
};

export default ScreenshotCarousel;
